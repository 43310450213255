import React from 'react'

const apiKey = 'AIzaSyAAR8I7s0TqzNOV-MmxwOrghBvdFRmPic4'

const MapBlock = ({ address }) => {
  const src = `https://www.google.com/maps/embed/v1/place?key=${apiKey}
    &q=${address}`

  return (
    <iframe
      src={src}
      width="100%"
      height="480px"
      frameBorder="0"
      allowFullScreen
      title="Contact map"
    />
  )
}

export default MapBlock
